import React from "react";

import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <Hero title={'Terms and Conditions'} />
    <Content>
      <div style={{width: '90%', maxWidth: '1080px', margin: 'auto'}}>
      <p style={{textAlign: 'left'}}>
      PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE. 
      By using our Site, you are agreeing to these Terms of Use. If you do not 
      agree, do not use the Site. These Terms of Use and Points’ Privacy Policy 
      (collectively, the "Agreement") govern your relationship to Points with 
      respect to your use of the Site.
      </p>
      <p style={{textAlign: 'left'}}>
      The website located at www.points.com ("Points.com" or the "Site") is owned and operated by Points.com Inc. ("Points"), an Ontario corporation, with its principal place of business at 111 Richmond St. W., Suite 700 Toronto, ON, M5H 2G4 Canada. When used herein the terms "we", "us", and "our" refer to Points.
      </p>
      <p style={{textAlign: 'left'}}>
      We reserve the right, at any time, to modify, alter, or update these terms and conditions of use. We will notify you of such changes by prominent notice on the Site. Your continued usage of the Site following such notification shall constitute your acceptance of the changes. If you do not agree to any such changes, you must immediately cease using the Site.
      </p>
      <h3>Use of Points.com Generally</h3>
        <p style={{textAlign: 'left'}}>
        The information on this website is intended to provide general information only on the various products and services offered by Points. Please read the Agreement carefully. If you do not accept all of these terms and conditions, please do not use this Site.
        </p>
      <h3>Prohibited Activities</h3>
      <p style={{textAlign: 'left'}}>
      The content and information on this Site are proprietary to us. You are licensed to use our Site solely for the purpose set out above. You are not permitted to copy our Site. You agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, from this Site. Additionally, you agree not to "frame", "mirror" or otherwise incorporate any part of this Site into any other website without our prior written authorization.
      </p>
      <h3>Proprietary Rights</h3>
      <p style={{textAlign: 'left'}}>
      Any data, text, graphics, images, audio and video clips, logos, icons, and links (collectively, the “Materials”), and any intellectual property and other rights relating thereto, are and will remain the property of Points, our suppliers and/or our loyalty program partners. The Materials and the selection, compilation, collection, arrangement, and assembly thereof are protected by U.S. and international copyright, trademark, and other laws, and you acknowledge that these rights are valid and enforceable. You must retain all copyright and other proprietary notices on downloaded and copied Materials, and any such downloads or copies are subject to the terms and conditions of this Agreement.
      </p>
      <p style={{textAlign: 'left'}}>
      The trademarks, logos, and service marks displayed on the Site (collectively the “Trademarks”) are the registered and unregistered trademarks of Points, our suppliers and/or loyalty program partners, or their respective licensors and suppliers. The Trademarks, whether registered or unregistered, may not be used in any manner that is likely to cause confusion with customers, or in any manner that disparages Points, our suppliers and/or loyalty program partners, or their respective licensors and suppliers. Nothing contained on the Site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark.
      </p>
      <h3>Linked Sites</h3>
      <p style={{textAlign: 'left'}}>
      We make no claim or representation regarding, and accept no responsibility for, the quality, content, nature, or reliability of sites accessible by hyperlink from this Site, or sites linking to this Site. The linked sites are not under the control of Points, and we are not responsible for the content of any linked site, or any link contained in a linked site, or any review, changes or updates to such sites. We are providing these links to you only as a convenience, and the inclusion of any link does not imply affiliation, endorsement, or adoption by Points of the site, or any information contained therein. When leaving the Site, you should be aware that our terms and policies no longer govern, and, therefore, you should review the applicable terms and policies, including privacy and data gathering practices, of that site. 
      </p>
      <h3>Privacy</h3>
      <p style={{textAlign: 'left'}}>
      Any business contact information you provide through the Site in order for us to provide you with further information regarding our products and services, including your name, title, email address and phone number, will be treated strictly in accordance with our Privacy Policy.
      </p>
      <h3>Changes</h3>
      <p style={{textAlign: 'left'}}>
      We reserve the right, at any time, to change the Site, including eliminating or discontinuing any content on or feature of the Site; Any changes we make will be effective immediately and automatically without further or special notice. Your continued use of the Site following such changes will be deemed acceptance of such changes.
      </p>
      <p style={{textAlign: 'left'}}>
      We further reserve the right at any time, without notice, to: (1) to modify, suspend or terminate operation of or access to the Site, or any portion of the Site, for any reason; (2) to modify or change the Site, or any portion of the Site, and any applicable policies or terms; and/or (3) to interrupt the operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance, error correction, or other changes.
      </p>
      <h3>Governing Law and Disputes</h3>
      <p style={{textAlign: 'left'}}>
      This Agreement and your use of the Site is governed exclusively by the laws of the Province of Ontario, Canada notwithstanding any principles of conflicts of law.
      </p>
      <h3>Disclaimer</h3>
      <p style={{textAlign: 'left'}}>
      You agree that your use of the Site shall be at your sole risk. The Site and the services offered thereon are provided "as is" without warranty, representation, condition or guarantee of any kind, either expressed or implied, including but not limited to any implied warranties, representations, conditions or guarantees of quality, merchantability, merchantable quality, fitness for a particular purpose or non-infringement, all of which are disclaimed to the fullest extent permitted by law. Applicable law may not allow the exclusion of implied warranties, so the above exclusions may not apply to you. Points nor any of its affiliates, licensors, suppliers, advertisers, sponsors, and agents warrant that your use of the Site will be uninterrupted, error-free or secure, that defects will be corrected, or that the Site, the server(s) on which the Site is hosted are free of viruses or other harmful components.
      </p>
      <h3>Limitation of Liability</h3>
      <p style={{textAlign: 'left'}}>
      Neither Points its suppliers and/or its loyalty program partners, nor any of our/their affiliates, licensors, suppliers, advertisers, or sponsors, nor any of our/their directors, officers, employees, contractors, consultants, agents, or other representatives, are responsible or liable for any indirect, incidental, consequential, special, exemplary, punitive, or other damages (including, without limitation, damages for loss of business, loss of data, or lost profits), under any contract, negligence, strict liability or other theory arising out of or relating in any way to the Site and/or materials contained on the Site, any linked site.
      </p>
      <p style={{textAlign: 'left'}}>
      Your sole remedy for dissatisfaction with the Site, materials, or any linked site is to stop using the Site, materials, or linked site, as applicable and we accept no liability for any damages, losses and causes of action, whether in contract, tort (including, without limitation, negligence) or otherwise. Some jurisdictions do not allow the exclusion of incidental or consequential damages, so the above limitation or exclusion may not apply to you.
      </p>
      <h3>Indemnity</h3>
      <p style={{textAlign: 'left'}}>
      You agree to indemnify, defend, and hold Points, and our respective affiliates, licensors, suppliers, advertisers, and sponsors, and their respective directors, officers, employees, contractors, consultants, agents, and other representatives, harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys’ fees), and other expenses that arise directly or indirectly out of or from (a) your breach of this Agreement; and/or (b) your activities in connection with the Site.
      </p>
      <h3>Entire Agreement, Waiver and Severability</h3>
      <p style={{textAlign: 'left'}}>
      This Agreement, together with any applicable transaction request and confirmation, and our Privacy Policy constitute the entire agreement between you and us with respect to your use of Points.com and the processing of any transactions thereon and supersede all prior or contemporaneous agreements or understandings, if any, whether written or oral, relating to such subject matter. No modification, amendment or waiver of this Agreement shall be effective or binding unless made in writing and signed by us. If any of the provisions of this Agreement shall be deemed invalid, void, or for any reason unenforceable, that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provision.
      </p>
      <h3>Force Majeure</h3>
      <p style={{textAlign: 'left'}}>
      We shall have no liability for delays or failure in performance caused by any event beyond our control.
      </p>
      <h3>Use of the Term "Partner"</h3>
      <p style={{textAlign: 'left'}}>
      The use of term "partner" on Points.com and in any other materials is not intended to indicate that such loyalty program is in a legal partnership with us. Rather, the term is used in a marketing sense to show participation by the "partners" in Points’ products and services.
      </p>
      </div>
    </Content>
  </Layout>
);

export default TermsPage;
